'use client';

import React, { useRef } from 'react';

import { mergeRefs } from '@madeinhaus/utils';
import cx from 'clsx';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';

import useScrollProgress from 'hooks/useScrollProgress';

import styles from './Hero.module.scss';

const IMAGE_MOBILE =
    'https://images.ctfassets.net/j8tkpy1gjhi5/7afx1yfjtU9vyIjXNdyv1p/7597c5a1ce851995ae10bb8f4ce716c3/mozzarella-mobile.png';

const IMAGE_TOP =
    'https://images.ctfassets.net/j8tkpy1gjhi5/9SvZaC2CjAwgrjBbguj74/13d239784718674234c8cfc147bd91e7/mozzarella-desktop-pull.png';
const IMAGE =
    'https://images.ctfassets.net/j8tkpy1gjhi5/600Gjq9KRSRYKQRwpdRBy0/73fcb190f1c2589401c92dbe83cef905/mozzarella-desktop.png';

const Hero = ({ showIntro }) => {
    const titleRef = useRef(null);
    const displayTitleRef = useRef(null);
    const buttonRef = useRef(null);

    const { setSection, setNode } = useScrollProgress({
        windowHeightBehavior: 'ignore',
        callback: progress => {
            const dist = `${progress * 6}vw`;
            titleRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
            displayTitleRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
            buttonRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
        },
    });

    return (
        <div
            className={cx(styles.root, {
                [styles.showIntro]: showIntro,
            })}
        >
            <div className={styles.hero} ref={setSection}>
                <div className={styles.inner}>
                    <div className={styles.headingGroup}>
                        <h1
                            className={styles.title}
                            ref={mergeRefs([setNode, titleRef])}
                        >
                            EXTRAORDINARY DAIRY
                        </h1>
                        <div
                            ref={displayTitleRef}
                            role="presentation"
                            className={cx(styles.title, styles.displayTitle)}
                        >
                            <span className={styles.hidden}>EXTRAOR</span>D
                            <span className={styles.hidden}>I</span>N
                            <span className={styles.hidden}>ARY DAIRY</span>
                        </div>
                        <ButtonCTA
                            ref={buttonRef}
                            className={styles.cta}
                            text={`MEET OUR MOZZARELLA`}
                            style="filled-blue-ocean"
                            link={{
                                url: '/products/cheese/whole-milk-mozzarella',
                            }}
                        />
                        <div className={styles.heroImage}>
                            <div>
                                <Img
                                    className={styles.image}
                                    src={IMAGE_MOBILE}
                                    fallbackImageWidth={768}
                                    alt={'Stack of Tillamook products.'}
                                    priority
                                    customSources={[
                                        {
                                            breakpoint: 1280,
                                            src: IMAGE,
                                            imageWidth: 2500,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: IMAGE,
                                            imageWidth: 1920,
                                        },
                                        {
                                            src: IMAGE_MOBILE,
                                            imageWidth: 1280,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div
                            className={cx(
                                styles.heroImage,
                                styles.heroImageTop
                            )}
                        >
                            <div>
                                <Img
                                    className={styles.image}
                                    src={IMAGE_TOP}
                                    fallbackImageWidth={768}
                                    role="presentation"
                                    alt={'Stack of Tillamook products.'}
                                    priority
                                    customSources={[
                                        {
                                            breakpoint: 1280,
                                            src: IMAGE_TOP,
                                            imageWidth: 2500,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: IMAGE_TOP,
                                            imageWidth: 1920,
                                        },
                                        {
                                            src: IMAGE_TOP,
                                            imageWidth: 1280,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    productNavigation: PropTypes.shape({
        navItemsCollection: PropTypes.shape({
            items: PropTypes.array.isRequired,
        }),
    }),
    showIntro: PropTypes.bool,
};

export default Hero;
